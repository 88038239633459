import React, { Component } from 'react';
import './App.css';

// Navigation
import { BrowserRouter as Router, Route, Switch, Link }  from 'react-router-dom'
import { Collapse, Nav, Navbar, NavbarToggler, NavItem } from 'reactstrap';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';

import { ArtistsPage, NavPage, LandingPage, ProcessPage, ProjectPage, QrLinkPage, SculpturesPage } from './component';
import { ScrollToTop, targetToText } from './utils';
const textStyle = {textAlign: "justify", textJustify:"inter-word"};


class AppNavBar extends Component {
  constructor(props) {
    super(props);

    this.callbacks = {
      toggleNavbar: this.toggleNavbar.bind(this),
      clickedNav: this.clickedNav.bind(this)
    };
    this.state = {
      collapsed: true
    };
  }

  toggleNavbar() {
    this.setState({collapsed: !this.state.collapsed});
  }

  clickedNav() {
    this.setState({collapsed: true});
  }


  render() {
    const pathname = this.props.location.pathname;
    const pageOrder = this.props.pageOrder;
    const height = (this.state.collapsed) ?
      this.props.height :
      this.props.height * pageOrder.length;
    let details = null, leaf = null;
    if (pathname === '/nav') {
      leaf = null;
      details = <BreadcrumbItem active>Table of Contents</BreadcrumbItem>
    } else if (pathname.startsWith("/2017052502")) {
      leaf = <BreadcrumbItem active style={{textTransform: 'capitalize'}}>{pathname.substring(1)}</BreadcrumbItem>;
      details = null;
    } else {
      leaf = <BreadcrumbItem active style={{textTransform: 'capitalize'}}>{pathname.substring(1)}</BreadcrumbItem>;
      details = <BreadcrumbItem><Link to="/nav">Table of Contents</Link></BreadcrumbItem>
    }
    return (
      <div>
        <Navbar style={{backgroundColor: "#e9ecef", height: height}} fixed="top" light>
          <Breadcrumb className="mr-auto">
            <BreadcrumbItem><Link to="/">Home</Link></BreadcrumbItem>
            {details}
            {leaf}
          </Breadcrumb>
          <NavbarToggler onClick={this.callbacks.toggleNavbar}
            className="mr-2 mb-2" />
          <Collapse isOpen={!this.state.collapsed} navbar>
            <Nav navbar style={{textTransform: "capitalize"}}>
              {
                pageOrder.map((d, i) =>
                  <NavItem onClick={this.callbacks.clickedNav} key={i}>
                    <Link to={d}>{targetToText(d)}</Link>
                  </NavItem>
                )
              }
            </Nav>
          </Collapse>
        </Navbar>
      </div>
    )
  }
}

class AppInternal extends Component {
  // The landing page has a more sparse set-up than the rest.
  render() {
    const pageOrder = ["/project", "/process", "/exhibitions", "/artists"];
    const height=60;
    return (
        <div style={textStyle}>
          <Route render={p => <AppNavBar key="navbar" {...p} height={height} pageOrder={pageOrder} />} />
          <main role="main" className="container-fluid" style={{position:'relative', top: height + 5}}>
            <Switch>
              <Route exact path="/nav" render={p => <NavPage key="nav" {...p} />} />
              <Route exact path="/project" render={p =>
                <ProjectPage key="project" pageOrder={pageOrder} {...p} />} />
              <Route exact path="/process" render={p =>
                <ProcessPage key="process" pageOrder={pageOrder} {...p} />} />
              <Route exact path="/exhibitions" render={p =>
                <SculpturesPage key="exhibitions" pageOrder={pageOrder}  {...p} />} />
              <Route exact path="/artists" render={p =>
                <ArtistsPage key="artists" pageOrder={pageOrder} {...p} />} />
              <Route exact path="/2017052502" render={p =>
                <QrLinkPage key="2017052502" {...p} />} />
              <Route exact path="/2017052502-2" render={p =>
                <QrLinkPage key="2017052502-2" {...p} />} />
            </Switch>
          </main>
        </div>
    );
  }
}

class App extends Component {

  componentDidMount() { this.props.doRetrieve() }

  componentWillReceiveProps(nextProps) {
    if (this.props.pages == null)
      nextProps.doRetrieve();
  }

  render() {
    return (
        <Router>
          <ScrollToTop>
            <Switch>
              <Route exact path="/"
                render={p => <LandingPage key="landing" {...p} />} />
              <Route render={p => <AppInternal key="internal" {...p} />} />
            </Switch>
          </ScrollToTop>
        </Router>
    );
  }
}

export default App;
