import MarkdownPageState from './MarkdownPageState';

const SiteState = {
  retrieve: () => {
    return (dispatch) => {
      return Promise.all([
        MarkdownPageState.retrieve("/pages/project.md")(dispatch),
        MarkdownPageState.retrieve("/pages/flamingo-short.md")(dispatch),
        MarkdownPageState.retrieve("/pages/flamingo-long.md")(dispatch),
        MarkdownPageState.retrieve("/pages/exhibitions.md")(dispatch),
        MarkdownPageState.retrieve("/pages/process/01-performance.md")(dispatch),
        MarkdownPageState.retrieve("/pages/process/02-data.md")(dispatch),
        MarkdownPageState.retrieve("/pages/process/03-form.md")(dispatch),
        MarkdownPageState.retrieve("/pages/process/04-materialization.md")(dispatch)
      ])
    }
  }
};

export default SiteState
