import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';

import MarkdownPage from '../markdown';
import { BottomNav } from '../../utils';

class ArtistsPage extends Component {
  render() {
    return [<Row key="short">
      <Col md={{size:6, order: 1}} xs={{order: 2}}>
        <MarkdownPage source="/pages/flamingo-short.md" />
      </Col>
      <Col md={{size: 6, order: 2}} xs={{order: 1}}>
      <h1>ILLPOSED</h1>
      Chandrasekhar Ramakrishnan is data scientist and media artist working at intersection of sound and data. He works under the label <a href="https://illposed.com">Illposed</a>.
      </Col>
    </Row>,
    <Row key="video">
      <Col md={{size:6}}>
        <iframe
          title="Flamingo Video"
          width="560"
          height="315"
          src="https://www.youtube-nocookie.com/embed/Q1o-VJAS9TA"
          frameborder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen></iframe>
      </Col>
    </Row>,
    <Row key="long">
      <Col md={{size:6}}>
        <MarkdownPage source="/pages/flamingo-long.md" />
      </Col>
    </Row>,
    <Row key="bottomnav">
      <Col>
        <BottomNav pageOrder={this.props.pageOrder} />
      </Col>
    </Row>
  ]}
}

export default ArtistsPage;
