import React, { Component } from 'react';
// import { Link, withRouter }  from 'react-router-dom';
import { withRouter }  from 'react-router-dom';

function targetToText(target) {
  return `${target.substring(1)}`;
}

class BottomNav extends Component {
  render() {
    // With the floating top-nav, there is nothing to show.
    // const pathname = this.props.location.pathname;
    // const pageOrder = this.props.pageOrder;
    // let navLinks = [];
    // const locIndex = pageOrder.indexOf(pathname);
    // if (locIndex > 0) {
    //   navLinks.push(
    //     <Link key="prev" to={pageOrder[locIndex - 1]}>
    //       &larr; {targetToText(pageOrder[locIndex - 1])}
    //     </Link>)
    //   if (locIndex + 1 < pageOrder.length) {
    //     navLinks.push(" | ");
    //   }
    // }
    // if (locIndex + 1 < pageOrder.length) {
    //   navLinks.push(
    //     <Link key="next" to={pageOrder[locIndex + 1]}>
    //       {targetToText(pageOrder[locIndex + 1])} &rarr;
    //     </Link>)
    // }
    // return <div className="pt-5 pb-3" style={{fontSize: "larger", textTransform: "capitalize"}}>{navLinks}</div>;
    return <div></div>
  }
}

export default withRouter(BottomNav);
export { targetToText };
