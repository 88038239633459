 import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import { Link }  from 'react-router-dom'

const linkTarget = "/nav"

class LandingPage extends Component {
  render() {
    const backgroundStyle = {
      "backgroundPosition": "100% 0px",
      "backgroundSize": "cover",
      "height": "600px"
    };
    const textStyle = {"backgroundColor": "rgba(0, 0, 0, 0.4)", padding: "5px"};
    // const textStyleJustify = {...textStyle, textAlign: "justify", textAlignLast: "justify"};
    const textStyleJustify = {...textStyle, textAlign: "center", textAlignLast: "center"};
    const panelColClassName = "text-white d-flex flex-column justify-content-center";
    return [
    <Row key="panel1">
      <Col style={{...backgroundStyle,
        "backgroundImage": 'url("/images/backgrounds/records.jpg")',
        "height": "400px"
      }} className={panelColClassName}>
        <div className="flex-fill">
          <Link className="text-white" to={linkTarget}><div style={textStyleJustify}><h2>Every Vessel Records and Dramatises the History of its Manufacture</h2></div></Link>
        </div>
        <div className="flex-fill">
          <Link className="text-white" to={linkTarget}><Row>
            <Col xs={{size: 10, offset: 1}} sm={{size: 8, offset: 1}} md={{ size: 6, offset: 1 }} style={textStyle}>
              Throughout most of the 20th Century, music had a physical representation.
              Music was distributed on physical media that could be aesthetically manipulated.
            </Col>
          </Row></Link>
        </div>
      </Col>
    </Row>,
    <Row key="panel2">
    <Col style={{...backgroundStyle, "backgroundImage": 'url("/images/backgrounds/flamingo.jpg")' }}
      className={panelColClassName}>
      <div>
        <Link className="text-white" to={linkTarget}><Row>
          <Col xs={{size: 10, offset: 1}} sm={{size: 8, offset: 1}} md={{ size: 6, offset: 1 }} style={textStyle}>
            In recent years, though, digital distribution of music has become increasingly dominant and, as a result,
            releases of music have become increasingly intangible. Music without a tangible medium loses an avenue for
            aesthetic communication.
          </Col>
        </Row></Link>
      </div>
    </Col>
    </Row>,
    <Row key="panel3">
    <Col style={{...backgroundStyle, "backgroundImage": 'url("/images/backgrounds/sculptures.jpg")'}}
      className={panelColClassName}>
      <div className="flex-fill"></div>
      <div className="flex-fill">
        <Link className="text-white" to={linkTarget}><Row>
          <Col xs={{size: 10, offset: 1}} sm={{size: 8, offset: 1}} md={{ size: 6, offset: 1 }} style={textStyle}>
            With 21st century tools, we can revive this avenue.
            Each artist can have their own physical representation. This is ours.
          </Col>
        </Row></Link>
      </div>
    </Col>
    </Row>,
  ]
  }
}

export default LandingPage;
