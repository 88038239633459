import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import { Link }  from 'react-router-dom'

class NavPage extends Component {
  render() {
    const backgroundStyle = {
      "backgroundPosition": "100% 0px",
      "backgroundSize": "cover",
      "height": "600px"
    };
    const textStyle = {"backgroundColor": "rgba(0, 0, 0, 0.4)", padding: "5px"};
    const panelColClassName = "text-white d-flex flex-column justify-content-center";
    return [
      <Row key="project">
        <Col style={{...backgroundStyle, "backgroundImage": 'url("/images/backgrounds/project.jpg")' }}
          className={panelColClassName}>
          <div>
            <Link className="text-white" to="/project"><div style={textStyle}><h1>Project</h1></div></Link>
          </div>
        </Col>
      </Row>,
      <Row key="process">
        <Col style={{...backgroundStyle, "backgroundImage": 'url("/images/backgrounds/process.jpg")' }}
          className={panelColClassName}>
          <div>
            <Link className="text-white" to="/process"><div style={textStyle}><h1>Process</h1></div></Link>
          </div>
        </Col>
      </Row>,
      <Row key="exhibitions">
        <Col style={{...backgroundStyle, "backgroundImage": 'url("/images/backgrounds/exhibitions.jpg")'}}
          className={panelColClassName}>
          <div>
            <Link className="text-white" to="/exhibitions"><div style={textStyle}><h1>Exhibitions</h1></div></Link>
          </div>
        </Col>
      </Row>,
      <Row key="artists">
        <Col style={{...backgroundStyle,
          "backgroundImage": 'url("/images/backgrounds/artists.jpg")'
        }} className={panelColClassName}>
          <div>
            <Link className="text-white" to="/artists"><div style={textStyle}><h1>Artists</h1></div></Link>
          </div>
        </Col>
      </Row>,
    ]
  }
}

export default NavPage;
