import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';

import MarkdownPage from '../markdown';
import { BottomNav } from '../../utils';

class ProjectPage extends Component {
  render() {
    return [<Row key="project">
      <Col lg={{size:6, order: 1}} xs={{order: 2}}>
        <MarkdownPage source="/pages/project.md" />
      </Col>
      <Col lg={{size: 6, order: 2}} xs={{order: 1}} >
        <iframe title="Interview"
          src="https://player.vimeo.com/video/217466526?title=0&byline=0&portrait=0"
          width="640" height="360" frameBorder="0" webkitallowfullscreen="true" mozallowfullscreen="true" allowFullScreen />
        <div><img width="100%" src={`/images/project/exhibition.jpg`} alt="Exhibition" /></div><br />
        <div><img width="100%" src={`/images/project/sculptures.jpg`} alt="Sculptures" /></div>
      </Col>
    </Row>,
    <Row key="bottomnav">
      <Col>
        <BottomNav pageOrder={this.props.pageOrder} />
      </Col>
    </Row>]
  }
}

export default ProjectPage;
