import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';

import MarkdownPage from '../markdown';
import { BottomNav } from '../../utils';

class ProcessPage extends Component {
  render() {
    return [<Row key="flamingo">
    <Col md={{size:6, order: 2}} xs={{order: 1}}>
      <img width="100%" src={`/images/process/flamingo.jpg`} alt="Flamingo" />
    </Col>
      <Col md={{size:6, order: 1}} xs={{order: 2}}>
        <MarkdownPage source="/pages/process/01-performance.md" />
      </Col>
    </Row>,
    <Row key="features" className="pt-5">
      <Col md={6}>
        <img width="100%" src={`/images/process/features.jpg`} alt="Data" />
      </Col>
      <Col md={6}>
        <MarkdownPage source="/pages/process/02-data.md" />
      </Col>
    </Row>,
    <Row key="exploration" className="pt-5">
      <Col md={{size:6, order: 2}} xs={{order: 1}}>
        <img width="100%" src={`/images/process/vessel.png`} alt="Form" />
      </Col>
      <Col md={{size:6, order: 1}} xs={{order: 2}}>
        <MarkdownPage source="/pages/process/03-form.md" />
      </Col>
    </Row>,
    <Row key="sculptures" className="pt-5">
      <Col md={6}>
        <img width="100%" src={`/images/process/materialization.jpg`} alt="Sculptures" />
      </Col>
      <Col md={6}>
        <MarkdownPage source="/pages/process/04-materialization.md" />
      </Col>
    </Row>,
    <Row key="bottomnav">
      <Col>
        <BottomNav pageOrder={this.props.pageOrder} />
      </Col>
    </Row>]
  }
}

export default ProcessPage;
