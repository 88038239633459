import React from 'react';
import ReactDOM from 'react-dom';
import './styles/index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

// State
import { Provider, connect } from 'react-redux';
import { SiteState, createGlobalStore } from './state';

const store = createGlobalStore();


function mapStateToProps(state, ownProps) {
  return {pages: state.pages};
}

function mapDispatchToProps(dispatch) {
  return {doRetrieve: () => dispatch(SiteState.retrieve()) }
}

const VisibleApp = connect(mapStateToProps, mapDispatchToProps)(App)

ReactDOM.render(<Provider store={store}><VisibleApp /></Provider>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
