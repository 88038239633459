import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';

class QrLinkPage extends Component {
  render() {

    return [
      <Row key="title">
        <Col sm={10} md={8} lg={6}>
          <h2>2017052502</h2>
        </Col>
      </Row>,
      <Row key="intro">
        <Col sm={10} md={8} lg={6}>
          <p>
          Sculpture <b>2017052502</b> is one of the 72 sculptures that constitute the multimedia installation performance <a href="/">Every Vessel records and dramatises the history of its manufacture</a> by Flamingo in collaboration with Chandrasekhar Ramakrishnan. The music was performed at 2 pm on the 25th of May 2017 and was the second of three performances that day.
          </p>
        </Col>
        <Col sm={6} md={6} lg={6}>
          <iframe title="bandcamp" style={{border: "0", width: "400px", height: "120px"}} src="https://bandcamp.com/EmbeddedPlayer/album=4093968676/size=large/bgcol=ffffff/linkcol=0687f5/tracklist=false/artwork=small/transparent=true/" seamless><a href="https://flamingoberlin.bandcamp.com/album/2017052502">2017052502 by Flamingo</a></iframe>
        </Col>
      </Row>,
      <Row key="closeups">
        <Col sm={3}>
          <img width="100%" src={`/images/2017052502/201705250201.jpg`} alt="201705250201" />
        </Col>
        <Col sm={3}>
          <img width="100%" src={`/images/2017052502/201705250202.jpg`} alt="201705250202" />
        </Col>
        <Col sm={3}>
          <img width="100%" src={`/images/2017052502/201705250203.jpg`} alt="20170525020203" />
        </Col>
        <Col sm={3}>
          <img width="100%" src={`/images/2017052502/201705250204.jpg`} alt="201705250204" />
        </Col>
      </Row>
    ]

  }
}

export default QrLinkPage;
